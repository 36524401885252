<script>
export default {
  name: 'LogoHilton',
  props: {
    fill: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
    },
  },
}
</script>

<template>
  <svg
    version="1.1"
    xml:space="preserve"
    :aria-labelledby="label"
    role="presentation"
    :class="{ 'fill-current': fill }"
    viewBox="0 0 960 576"
  >
    <g
      id="g10"
      transform="matrix(1.3333333,0,0,-1.3333333,0,576)"
    >
      <g id="g12" transform="scale(0.1)">
        <path
          id="path14"
          d="M 38.8281,1487.6 H 7173.93 V 4137.25 H 38.8281 Z M 160.41,4015.75 H 7052.43 V 1609.05 H 160.41 v 2406.7"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
          :fill="fill ? '#000000' : 'currentColor'"
        />
        <path
          id="path16"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 1948.73,3444.57 v -1262.2 l 98.91,-13.06 v -73.93 h -490.03 v 73.93 l 105.48,13.06 v 574.57 h -626.18 v -574.57 l 96.69,-13.06 v -73.93 H 652.453 v 73.93 l 96.692,13.06 v 1262.2 l -96.692,13.06 v 71.84 H 1133.6 v -71.84 l -96.69,-13.06 v -539.68 h 626.18 v 539.68 l -105.48,13.06 v 71.84 h 490.03 v -71.84 l -98.91,-13.06"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path18"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 2616.72,2173.66 92.25,-13.05 v -65.23 h -450.38 v 65.23 l 92.34,13.05 v 864.04 l -92.34,12.97 v 65.32 h 358.13 v -942.33"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path20"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 2904.57,2158.74 90.12,12.62 v 1282.13 l -90.12,12.71 v 63.27 h 358.17 V 2171.36 l 92.25,-12.62 v -63.4 h -450.42 v 63.4"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path22"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 3902.15,3115.99 h 241.64 v -95.74 h -241.64 v -607.16 c 0,-156.7 32.85,-206.79 175.67,-206.79 52.79,0 99,0 142.91,10.97 v -69.67 c -129.68,-45.74 -239.56,-65.32 -331.85,-65.32 -206.48,0 -254.83,93.56 -254.83,272.1 v 665.87 h -188.94 v 95.74 h 195.6 v 213.22 l 261.44,78.42 v -291.64"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path24"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 5354.51,2595.89 c 0,-317.75 -215.22,-530.97 -553.64,-530.97 -327.45,0 -536.24,139.29 -536.24,530.97 0,328.63 215.36,548.42 553.78,548.42 334.02,0 536.1,-198.12 536.1,-548.42 m -294.38,6.49 c 0,322.14 -96.64,463.61 -252.78,463.61 -151.52,0 -248.29,-134.99 -248.29,-463.61 0,-324.24 96.77,-459.13 248.29,-459.13 162.71,0 252.78,128.32 252.78,459.13"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path26"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 6576.16,2160.65 v -65.31 h -450.43 v 65.31 l 90.17,13.01 v 581.11 c 0,161 -59.31,232.85 -182.46,232.85 -57.14,0 -147.21,-39.17 -213.1,-84.86 v -729.1 l 92.26,-13.01 v -65.31 h -452.52 v 65.31 l 92.17,13.01 v 857.51 l -92.17,12.97 v 71.8 h 360.26 v -141.47 c 112.01,89.25 219.71,161.14 371.32,161.14 202.13,0 292.2,-117.58 292.2,-348.21 v -613.74 l 92.3,-13.01"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path28"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 2629.75,3385.82 c 0,-79.33 -64.93,-143.64 -144.99,-143.64 -80.16,0 -145.04,64.31 -145.04,143.64 0,79.33 64.88,143.65 145.04,143.65 80.06,0 144.99,-64.32 144.99,-143.65"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path30"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 7061.83,1305.85 h 21.59 c 14.4,0 19.32,4.13 19.32,14.35 0,10.32 -4.92,14.19 -19.32,14.19 h -21.59 z m 58.62,15.4 c 0,-13.84 -5.66,-22.63 -18.24,-26.2 l 21.85,-32.42 h -19.8 l -19.8,29.81 c -1.57,0 -3.09,-0.22 -5.4,-0.22 h -17.23 v -29.59 h -17.49 v 87.43 h 38.55 c 26.5,0 37.56,-8.97 37.56,-28.81 m -111.58,-15.67 c 0,-39.86 32.68,-72.54 72.5,-72.54 39.6,0 72.5,32.68 72.5,72.54 0,39.82 -32.9,72.5 -72.5,72.5 -39.82,0 -72.5,-32.68 -72.5,-72.5 m 165.06,-0.3 c 0,-51.13 -41.38,-92.51 -92.56,-92.51 -51.13,0 -92.56,41.38 -92.56,92.51 0,51.17 41.43,92.6 92.56,92.6 51.18,0 92.56,-41.43 92.56,-92.6"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path32"
          :fill="fill ? '#000000' : 'currentColor'"
          d="M 471.129,424.641 H 258.918 L 364.848,674.43 Z M 441.555,812.539 H 287.664 l -259.6835,-616.5 -1.9102,-4.5 H 169.289 l 56.109,134.91 h 279.586 l 55.485,-132.918 0.824,-1.992 h 143.223 l -262.661,620.27 -0.3,0.73"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path34"
          :fill="fill ? '#000000' : 'currentColor'"
          d="M 919.84,812.539 H 798.906 V 191.582 H 1244.82 V 300.781 H 919.84 v 511.758"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path36"
          :fill="fill ? '#000000' : 'currentColor'"
          d="M 1461.51,812.539 H 1340.58 V 191.582 h 445.92 v 109.199 h -324.99 v 511.758"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path38"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 1833.35,374.48 h 308.97 v 96.219 H 1833.35 V 374.48"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path40"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 2243.94,191.539 h 120.94 V 812.48 H 2243.94 V 191.539"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path42"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 2954.52,389.301 -319.48,421.949 -0.96,1.281 -125.13,0.008 V 191.582 h 118.93 V 614.59 l 319.31,-421.731 0.97,-1.277 h 125.29 V 812.539 H 2954.52 V 389.301"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path44"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 3675.7,370.148 c -38.33,-47.476 -93.41,-73.636 -155.11,-73.636 -112.78,0 -201.12,90.258 -201.12,205.476 0,115.242 88.34,205.512 201.12,205.512 61.67,0 116.75,-26.16 155.1,-73.648 l 1.73,-2.141 104.17,59.391 -2.26,2.937 c -61.82,80.852 -156.41,127.231 -259.53,127.231 -179.6,0 -325.72,-143.231 -325.72,-319.282 0,-176.019 146.12,-319.226 325.72,-319.226 103.12,0 197.72,46.359 259.55,127.199 l 2.26,2.949 -104.18,59.391 -1.73,-2.153"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path46"
          :fill="fill ? '#000000' : 'currentColor'"
          d="M 4010.06,812.539 H 3889.13 V 191.582 h 445.91 v 109.199 h -324.98 v 511.758"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path48"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 4833.41,472.051 c 0.01,-58.422 -14.56,-103.653 -43.31,-134.422 -26.77,-28.66 -66.1,-43.809 -113.75,-43.809 -99.82,0 -157.07,64.961 -157.08,178.231 V 812.469 H 4398.33 V 472.051 c 0,-183.852 101.33,-289.289 278.01,-289.289 176.67,0 278,105.437 278,289.289 V 812.539 H 4833.41 V 472.051"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path50"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 5343.52,570.051 -0.02,0.008 c -111.54,28.703 -130.38,44.05 -130.38,75.64 0,45.16 38.03,72.199 101.72,72.321 h 0.34 c 67.37,0 149.3,-22.098 213.81,-57.68 l 2.85,-1.559 51.37,93.879 -2.62,1.621 c -68.93,42.528 -161.23,66.93 -253.22,66.93 h -0.41 c -152.66,-0.121 -243.8,-70.852 -243.8,-189.199 0,-110.481 93.86,-142.711 226.45,-176.172 7.79,-1.988 15.26,-3.891 22.41,-5.699 103.51,-26.25 138.37,-35.09 138.37,-91.469 0,-16.551 -5.79,-30.723 -17.22,-42.121 -18.71,-18.66 -52.79,-29.36 -93.49,-29.36 h -0.69 c -85.09,0.129 -174.71,24.84 -245.85,67.797 l -2.77,1.672 -54.28,-89.918 -0.59,-0.972 2.57,-1.719 c 78.28,-52.422 180.7,-81.289 288.37,-81.289 154.79,0 250.94,72.82 250.94,190.058 0,129.578 -100.99,156.489 -253.86,197.231"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path52"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 5709.92,191.539 h 120.94 V 812.48 H 5709.92 V 191.539"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path54"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 6255.17,337.129 -185.38,473.34 -0.8,2.07 h -143.31 l 252.21,-621 h 154.04 l 252.94,619.352 0.67,1.648 h -143.32 l -187.05,-475.41"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
        <path
          id="path56"
          :fill="fill ? '#000000' : 'currentColor'"
          d="m 6800.93,300.73 v 152.75 h 337.91 v 104.219 h -337.91 v 145.633 h 364.73 V 812.539 H 6679.99 V 191.582 l 492.69,-0.043 V 300.73 h -371.75"
          style="fill-opacity: 1; fill-rule: nonzero; stroke: none"
        />
      </g>
    </g>
  </svg>
</template>
